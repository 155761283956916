/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ProductList {
  margin-bottom: 26px;
  margin-top: 15px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $custom-350-media ) {

  .ProductList {
    text-align: center;
  }
}
