/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ProductAdvertisement {
  border: 1px solid $greyLightest;
  display: inline-block;
  height: 522px;
  margin-bottom: 16px;
  position: relative;
  vertical-align: top;
  width: 31.22%;

  &.ProductAdvertisementDouble {
    width: 65.5%;
  }

  &.ProductAdvertisementAdvert {
    box-shadow: 0 0 8px rgba(33, 34, 36, 0.24);
    height: 502px;
  }
}

.ProductAdvertisementBorder {
  position: relative;

  &:after {
    border: 1px solid $greyLightest;
    content: '';
    height: calc(100% - 16px);
    left: 0;
    margin: 7px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: calc(100% - 16px);
  }
}

.ProductAdvertisementBackground {
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    display: block;
    height: 100%;
    object-fit: initial;
    width: 100%;
  }
}

.ProductAdvertisementBackgroundTop {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  img {
    display: block;
    object-fit: initial;
    width: 100%;
  }
}

.ProductAdvertisementBackgroundBottom {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;

  img {
    display: block;
    object-fit: initial;
    width: 100%;
  }
}

.ProductAdvertisementContent {
  left: 0;
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  width: 100%;
  z-index: 1;

  a {
    margin-top: 36px;
  }
}

.ProductAdvertisementContentLeft {

  .ProductAdvertisementContent {
    text-align: left;
  }

  .ProductAdvertisementOptions {
    text-align: left;
  }
}

.ProductAdvertisementContentRight {

  .ProductAdvertisementContent {
    text-align: right;
  }

  .ProductAdvertisementOptions {
    text-align: right;
  }
}

.ProductAdvertisementContentCenter {

  .ProductAdvertisementContent {
    text-align: center;
  }

  .ProductAdvertisementOptions {
    text-align: center;
  }
}

.ProductAdvertisementContentTop {

  .ProductAdvertisementContent {
    top: 40px;
  }
}

.ProductAdvertisementContentMiddle {

  .ProductAdvertisementContent {
    top: 50%;
    transform: translateY(-50%);
  }
}

.ProductAdvertisementContentBottom {

  .ProductAdvertisementContent {
    bottom: 24px;
  }
}

.ProductAdvertisementHeader {
  font-family: $fontFamily;
  font-size: $fontSize-xxl;
  font-weight: $font-weight-m;
  line-height: 36px;
  margin-bottom: 12px;
  padding-bottom: 11px;
  position: relative;

  .ProductAdvertisementSubtitle {
    font-family: $fontFamily;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: 1.2;
    padding: 5px 0 0;
  }
}

.ProductAdvertisementHeaderUnderline {
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

.ProductAdvertisementOptions {

  li {
    font-size: $fontSize-sm;
    line-height: 22px;
    margin-bottom: 12px;
    padding-left: 24px;
    position: relative;

    &:before {
      font-size: $fontSize-Icon12;
      left: 3px;
      position: absolute;
      top: 2px;
    }
  }
}

.ProductAdvertisementButton {
  bottom: 24px;
  padding: 0 16px;
  position: absolute;
  width: 100%;
}

.ProductAdvertisementDouble {

  .ProductAdvertisementHeader {
    font-size: 48px;
    line-height: 64px;
  }

  .ProductAdvertisementContent {
    padding-left: 36px;
    padding-right: 30px;
  }

  .ProductAdvertisementOptions {

    li {
      font-size: $fontSize-l;
      line-height: 20px;
      margin-bottom: 18px;

      &:before {
        top: 4px;
      }
    }
  }

  .ProductAdvertisementButton {
    bottom: 36px;
  }

  a {
    margin-left: auto;
    margin-right: auto;
    max-width: 200px;
  }

  &.ProductAdvertisementContentBottom {

    .ProductAdvertisementContent {
      bottom: 36px;
    }
  }

  &.ProductAdvertisementContentLeft {

    .ProductAdvertisementContent {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.ProductAdvertisementSingleText {
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  left: 0;
  line-height: 22px;
  padding-bottom: 30px;
  width: 100%;
}

.ProductAdvertisementLabel {
  left: 16px;
  position: absolute;
  top: 16px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $desktop-only-media ) {

  .ProductAdvertisementCardDesktop {
    margin-left: 3.1%;
    margin-right: 3.1%;
  }

  .ProductAdvertisement {

    &.ProductAdvertisementDouble {

      &.ProductAdvertisementDoubleLeft {
        margin-left: 0;
        margin-right: 3.1%;
      }

      &.ProductAdvertisementDoubleRight {
        margin-left: 3.1%;
        margin-right: 0;
      }
    }
  }
}

@media screen and ( $tablet-media ) and ( $above-mobile-media ) {

  .ProductAdvertisement {
    width: 22.7%;

    &.ProductAdvertisementCardTablet {

      &.ProductAdvertisementCardTabletFirst {
        margin: 0 1.5% 0 3.1%;
      }

      &.ProductAdvertisementCardTabletSecond {
        margin: 0 3.1% 0 1.5%;
      }
    }

    &.ProductAdvertisementDouble {
      width: 48.5%;
    }
  }
}

@media screen and ( $tablet-media ) {

  .ProductAdvertisementBorder:not(.ProductAdvertisementDouble) {

    &:after {
      display: none;
    }
  }

  .ProductAdvertisementHeader {
    font-size: 22px;
    line-height: 26px;
  }

  .ProductAdvertisementContent {
    padding-left: 8px;
    padding-right: 8px;

    a {
      margin-top: 32px;
    }
  }

  .ProductAdvertisementOptions {

    li {
      font-size: $fontSize-s;
      line-height: 20px;
    }
  }

  .ProductAdvertisement {
    height: 438px;

    &.ProductAdvertisementAdvert {
      height: 438px;
    }

    &.ProductAdvertisementDouble {

      .ProductAdvertisementContent {
        padding-left: 24px;
        padding-right: 24px;
      }

      .ProductAdvertisementHeader {
        font-size: $fontSize-xxl;
        line-height: 36px;
      }

      .ProductAdvertisementOptions li {
        font-size: $fontSize-m;
        line-height: 22px;
        margin-bottom: 8px;
      }
    }
  }

  .ProductAdvertisementButton {
    padding: 0 8px;
  }

  .ProductAdvertisementSingleText {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and ( $mobile-media ) {

  .ProductAdvertisement {
    width: 48%;

    &:nth-child(odd) {
      margin-right: 2%;
    }

    &:nth-child(even) {
      margin-left: 2%;
    }

    &.ProductAdvertisementDouble {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}

@media screen and ( $custom-370-media ) {

  .ProductAdvertisement {
    width: 100%;

    &:nth-child(odd) {
      margin-right: 0;
    }

    &:nth-child(even) {
      margin-left: 0;
    }
  }
}
